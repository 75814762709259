import { toast } from 'react-toastify';

import { twMerge } from 'tailwind-merge';

import { CloseIcon } from '@shared/assets';

import { TOAST_VARIANT, TOAST_VARIANT_STYLE } from './toastStyle';

interface IProps {
  title: string;
  variant?: TOAST_VARIANT;
  description?: string;
  disabledClose?: boolean;
  Icon?: JSX.Element;
}

const Toast = ({
  variant = TOAST_VARIANT.SUCCESS,
  title,
  description,
  disabledClose,
  Icon,
}: IProps) => {
  const dismiss = () => toast.dismiss();

  return (
    <div
      className={twMerge(
        'flex w-full items-center justify-between rounded-br-lg rounded-tr-lg border-l-2 p-4 xs:p-6',
        TOAST_VARIANT_STYLE[variant].className
      )}
    >
      <div className="flex gap-4">
        <div className="shrink-0">
          {Icon || TOAST_VARIANT_STYLE[variant].icon}
        </div>
        <div className="flex flex-col gap-1">
          <p
            className={twMerge(
              'font-large font-bold',
              TOAST_VARIANT_STYLE[variant].titleText
            )}
          >
            {title}
          </p>
          {description && <p className="font-large">{description}</p>}
        </div>
      </div>
      {!disabledClose && (
        <button
          onClick={() => dismiss()}
          aria-label="Close notification"
          className="ml-3"
        >
          <CloseIcon className="size-4 text-white" />
        </button>
      )}
    </div>
  );
};

export default Toast;
