import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { IToastPayload } from '@types';

import Toast from '@components/toast/Toast';
import { TOAST_VARIANT } from '@components/toast/toastStyle';

import {
  DEFAULT_NETWORK_ERROR_MESSAGE,
  WALLET_ACTION_ERROR_CODES,
} from '@shared/constants/errors';

const toastInfo = (
  label: string,
  { autoClose, id, ...props }: IToastPayload = {}
) =>
  toast(<Toast title={label} variant={TOAST_VARIANT.INFO} {...props} />, {
    autoClose: autoClose,
    toastId: id || 'info-toast',
  });

const toastSuccess = (
  label: string,
  { autoClose, id, ...props }: IToastPayload = {}
) =>
  toast(<Toast title={label} {...props} />, {
    autoClose: autoClose,
    toastId: id || 'success-toast',
  });

const toastError = (
  label = DEFAULT_NETWORK_ERROR_MESSAGE,
  { autoClose, id, ...props }: IToastPayload = {}
) =>
  toast(<Toast title={label} variant={TOAST_VARIANT.ERROR} {...props} />, {
    autoClose: autoClose,
    toastId: id || 'error-toast',
  });

const toastWarning = (
  label: string,
  { autoClose, id, ...props }: IToastPayload = {}
) =>
  toast(<Toast title={label} variant={TOAST_VARIANT.WARNING} {...props} />, {
    autoClose: autoClose,
    toastId: id || 'warning-toast',
  });

const toastTxError = (error: { cause: { code: number } }) => {
  let message: string;

  const errorCode = error?.cause?.code;
  if (errorCode) {
    message = WALLET_ACTION_ERROR_CODES[errorCode]?.message;
  } else {
    message = DEFAULT_NETWORK_ERROR_MESSAGE;
  }

  toastError('Transaction request failed', { description: message });
};

export { toastError, toastInfo, toastSuccess, toastWarning, toastTxError };
