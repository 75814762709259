import { ManagementListParams } from '@interfaces';
import {
  UserProductListParams,
  UserPurchaseHistoryParams,
} from '@interfaces/marketplace/products';
import { IFetchParams } from '@interfaces/queryParams';

import { ESortDirection } from '@shared/enums/queries';

export const PRODUCT_DETAILS = 'product_details';
export const PRODUCT_TOTAL_RATING = 'product-total-rating';
export const PRODUCT_REVIEWS = 'product-reviews';
export const USER_ME = ['user-me'];
export const ADMIN_ME = ['admin-me'];
export const TOKEN_RATES = ['token-rates'];

export const PRODUCTS = 'products';

export const FEATURED_PRODUCTS = 'featured-products';
export const HOMEPAGE_STATISTIC = 'homepage-statistic';

export const PRODUCT_SEARCH_INPUT = 'product-search-input';
export const CURRENCY_RATE = 'currency-rate';
export const USER_PRESIGNED_FILE = 'user-presigned-file';
export const PUBLISHER_PRESIGNED_FILE = 'publisher-presigned-file';
export const ADMIN_PRESIGNED_FILE = 'admin-presigned-file';

export const defaultParams = {
  keyword: '',
  limit: 12,
  page: 1,
};

//MARKETPLACE
export const PRODUCT_LIST_KEY = (params?: UserProductListParams) => [
  PRODUCTS,
  { ...params },
];

export const MARKETPLACE_TAGS = 'marketplace-tags';
export const PRODUCT_CATEGORIES = 'product-categories';

export const PRODUCT_TYPES_KEY = () => ['product-types'];

//USER
export const USER_FEE_SETTINGS = 'user-fee-settings';
export const USER_SETTLEMENT_SETTINGS = ['user-settlement-settings'];

//PUBLISHER
export const PUBLISHER_ME = ['publisher-me'];
export const PUBLISHER_PRODUCTS = 'publisher-products';
export const PUBLISHER_SALES = 'publisher-sales';
export const PUBLISHER_PRODUCT_STATISTICS = 'publisher-sales-statistics';
export const PUBLISHER_RECEIPT = 'publisher-receipt';
export const PUBLISHER_STATISTICS = 'publisher-statistics';
export const PUBLISHER_BALANCE_STATISTICS = 'publisher-balance-statistics';
export const PUBLISHER_WITHDRAW_REQUESTS = 'publisher-withdraw-requests';

export const PUBLISHER_SUBMITTED_PRODUCTS = 'publisher-products-submissions';
export const PUBLISHER_SUBMITTED_PRODUCT_DETAILS =
  'publisher-submitted_product_details';
export const PUBLISHER_PROFILE_PRODUCTS = 'publisher-profile-products';
export const PUBLISHER_KEYS = {
  PROFILE: ['publisher-my-profile'],
  REGISTRIES: ['harbor-account'],
};
export const REVIEW_OVERVIEW_KEY = 'review-overview';

export const PUBLISHER_PRODUCT_TYPES = 'publisher-product-types';
export const PUBLISHER_PRODUCT_CATEGORIES = 'publisher-product-categories';
export const PUBLISHER_PRODUCT_TAGS = 'publisher-product-tags';
export const PUBLISHER_SETTLEMENT_SETTINGS = 'publisher-settlement-settings';
export const PUBLISHER_FEE_SETTINGS = 'publisher-fee-settings';
export const PUBLISHER_SERVICE_MODEL = 'publisher-service-model';
export const PUBLISHER_CHOOSE_SERVICE_MODEL = 'publisher-choose-service-model';
export const PUBLISHER_SELECTED_MODEL_DETAIL =
  'publisher-selected_model_detail';

export const PUBLISHER_REFUND_REQUESTS = 'publisher-refund-requests';
export const PUBLISHER_REFUND_HISTORIES = 'publisher-refund-histories';
export const PUBLISHER_AVAILABLE_EARNINGS = 'publisher-available-earnings';
export const PUBLISHER_BALANCE_STATS = 'publisher-balance-stats';
export const PUBLISHER_WITHDRAWS = 'publisher-withdraws';

// ADMIN
export const ADMIN_SALES = 'admin-sales';
export const ADMIN_SYSTEM_OVERVIEW = 'admin-system-overview';
export const ADMIN_DEPOSITS = 'admin-deposit';
export const ADMIN_WITHDRAWS = 'admin-withdraws';
export const ADMIN_WITHDRAWS_LIST = 'admin-withdraws-list';
export const ADMIN_SYSTEM_BALANCE = 'admin-system-balance';
export const ADMIN_DEPOSITS_TRANSACTIONS = 'admin-deposit-transactions';
export const ADMIN_SUBMISSIONS = 'admin-submissions';
export const ADMIN_PRODUCTS = 'admin-products';
export const ADMIN_PRODUCT_DETAILS = 'admin-product-details';
export const ADMIN_REFUND_REQUESTS = 'refund-requests';
export const ADMIN_REFUND_HISTORIES = 'admin-refund-histories';
export const ADMIN_FEATURED_PRODUCTS = 'featured-products';
export const ADMIN_SETTLEMENT_SETTINGS = 'admin-settlement-settings';
export const ADMIN_SALES_RECEIPT = 'admin-sales-receipt';
export const ADMIN_SUBMISSION_DETAILS = 'admin-submission-details';
export const ADMIN_FEE_SETTINGS = 'admin-fee-settings';
export const ADMIN_TRANSACTIONS_NOWPAYMENTS = 'admin-transaction-nowpayments';
export const ADMIN_TRANSACTIONS_NOWPAYMENTS_STATS =
  'admin-transaction-nowpayments-stats';

export const PROFILE_KEYS = {
  PURCHASED_APPS: 'purchased-apps',
  PURCHASES: ['purchases'],
  PURCHASE_INFO: ['purchase-info'],
  PRODUCT_API_KEY: ['product-api-key'],
  PUBLISHER_PROFILE: (id: string) => ['publisher-profile', id],
  PUBLISHER_PROFILE_PRODUCT_LIST: (params?: UserProductListParams) => [
    PUBLISHER_PROFILE_PRODUCTS,
    { ...defaultParams, limit: 9, ...params },
  ],
  PURCHASE_HISTORY: (params?: UserPurchaseHistoryParams) => [
    'purchase-history',
    { sortDirection: ESortDirection.DESC, ...params },
  ],
  SUBSCRIPTION_RECEIPT: (id: string) => ['subscription-recepit', id],
  PURCHASE_RECEIPT: (id: string) => ['purchase-recepit', id],
  REFUNDS: ['refunds'],
  NOW_PAYMENTS: ['now-payments'],
  NOW_PAYMENT_INFO: ['now-payment-info'],
  DIRECT_DEPOSITS: ['direct-deposits'],
};

//USER REFUND
export const REFUND_HISTORIES = 'refund-histories';

//ADMIN MANAGEMENT
export const ADMIN_MANAGEMENT_PUBLISHER_LIST_KEY = (
  typeofList: string,
  params?: ManagementListParams
) => [`admin-management-publisher-list-${typeofList}`, { ...params }];
export const ADMIN_MANAGEMENT_USER_LIST_KEY = (
  params?: ManagementListParams
) => [`admin-management-user-list`, { ...params }];

export const ADMIN_CATEGORIES_SETTINGS_KEY = (params?: IFetchParams) => [
  'admin-categories-settings',
  { ...params },
];

export const ADMIN_TAGS_SETTINGS_KEY = (params?: IFetchParams) => [
  'admin-tags-settings',
  { ...params },
];

//ADMIN NOWPAYMENT
export const ADMIN_NOWPAYMENTS_SETTINGS = 'admin-nowpayments-settings';

//FORUM
export const FORUM_KEYS = {
  roles: {
    publisher: 'publisher',
    user: 'user',
  },
  threads: {
    threadList: 'thread-list',
    myThreads: 'my-threads',
    thread: 'thread',
  },
  notifications: {
    notificationsList: 'notifications-list',
    hasNewNotifications: 'has-new-notifications',
  },
  comments: {
    reportedComment: 'reported-comment',
    reports: 'reports',
    commentsList: 'comment-list',
    replyList: 'reply-list',
  },
  aiTrends: {
    trendingKeywords: 'trending-keywords',
    trendingIdeas: 'trending-ideas',
  },
};
export const FORUM_LOGIN_MODAL = ['forum-login-modal'];
export const IS_FORUM_LOGIN_IN_PROCESS = ['is-forum-login-in-process'];
export const FORUM_ROLE_MODAL = ['forum-role-modal'];

//ADMIN FORUM
export const ADMIN_FORUM_MANAGEMENT = 'admin-forum-management';
export const ADMIN_FORUM_CREATED_CONTENT = 'admin-forum-created-content';

//NOW PAYMENT
export const FIAT_CURRENCIES = 'fiat-currencies';
export const CRYPTO_CURRENCIES = 'crypto-currencies';
export const ESTIMATE_RATE = 'estimate-rate';
export const ESTIMATE_RATE_AITECH = 'estimate-rate-aitech';
export const ESTIMATE_RATE_USDAI = 'estimate-rate-usdai';
export const MIN_AMOUNT = 'min-amount';

//UPCOMING PRODUCTS
export const UPCOMING_PRODUCTS = 'upcoming-products';
export const LAYER1_UPCOMING_PRODUCTS = 'layer1-upcoming-products';
export const LAYER2_UPCOMING_PRODUCTS = 'layer2-upcoming-products';
export const LAYER3_UPCOMING_PRODUCTS = 'layer3-upcoming-products';
export const LAYER4_UPCOMING_PRODUCTS = 'layer4-upcoming-products';
