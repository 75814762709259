import {
  readAccessTokenForCurrentAdmin,
  readAccessTokenForCurrentPublisher,
  readAccessTokenForCurrentUser,
} from '@shared/utils';

import { RequestBuilder } from '../requestBuilder';

export class ServerRequestBuilder<T> extends RequestBuilder<T> {
  constructor(url?: string);
  constructor(callback: (requestInit: RequestInit) => Promise<T>);

  constructor(
    arg: string | ((requestInit: RequestInit) => Promise<T>) | undefined
  ) {
    super(arg);
  }

  private async authenticateUser(readAccessToken: () => string | undefined) {
    const accessToken = await readAccessToken();

    //This is a quick fix for the issue involving fetching products on the homepage with and without credentials.
    if (!accessToken) return;

    this.requestInit = {
      ...this.requestInit,
      headers: {
        ...this.requestInit?.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    };

    return this;
  }

  public async authenticate() {
    return this.authenticateUser(readAccessTokenForCurrentUser);
  }

  public async authenticateAdmin() {
    return this.authenticateUser(readAccessTokenForCurrentAdmin);
  }

  public async authenticatePublisher() {
    return this.authenticateUser(readAccessTokenForCurrentPublisher);
  }

  public override async call(
    url?: string,
    updateRequestInit?: (init: RequestInit) => RequestInit
  ) {
    return super.call(url, updateRequestInit);
  }
}
