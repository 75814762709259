import { Address } from 'viem';

export const config = {
  userApiUrl: process.env.NEXT_PUBLIC_USER_API_URL!,
  adminApiUrl: process.env.NEXT_PUBLIC_ADMIN_API_URL!,
  publisherApiUrl: process.env.NEXT_PUBLIC_PUBLISHER_API_URL!,
  s3bucketUrl: process.env.NEXT_PUBLIC_S3_BUCKET_URL!,

  veriffApiKey: process.env.NEXT_PUBLIC_VERIFF_API_KEY!,

  projectId: process.env.NEXT_PUBLIC_PROJECT_ID!,
  websiteUrl: process.env.NEXT_PUBLIC_WEBSITE_URL!,
  websiteDescription: process.env.NEXT_PUBLIC_WEBSITE_DESCRIPTION,
  websiteTitle: 'AI Marketplace',
  ogImage: process.env.NEXT_PUBLIC_OG_IMAGE,
  chainId: +process.env.NEXT_PUBLIC_CHAIN_ID!,
  chainName: process.env.NEXT_PUBLIC_CHAIN_NAME!,
  blockchainExplorerUrl: process.env.NEXT_PUBLIC_BLOCK_EXPLORER_URL!,
  aitechTokenAddress: process.env
    .NEXT_PUBLIC_AITECH_TOKEN_CONTRACT_ADDRESS! as Address,
  smcPaymentAddress: process.env.NEXT_PUBLIC_SMC_PAYMENT_ADDRESS! as Address,

  zendeskKey: process.env.NEXT_PUBLIC_ZENDESK_KEY,

  avaChatUrl: process.env.NEXT_PUBLIC_AVA_CHAT_URL!,
  recaptcha: {
    siteKey: process.env.NEXT_PUBLIC_SITE_KEY as string,
  },
  maintenanceModeWarning:
    process.env.NEXT_PUBLIC_MAINTENANCE_MODE_WARNING?.toString() === 'true',

  hotjarId: process.env.NEXT_PUBLIC_HOTJAR_ID!,
};
