export const HOME = '/';
export const PUBLISHER = '/publisher';
export const PROFILE = '/my-profile';
export const PURCHASED_APPS = '/purchased-apps';
export const DIRECT_DEPOSITS = '/direct-deposits';
export const NOWPAYMENTS = '/nowpayments';
export const REFUND = '/refund';
export const FORUM = '/forum';

export const PRODUCTS = '/products';
export const USERS = '/users';

export const PUBLISHER_PROFILE = (id?: number | string) =>
  `/user-profile/${id}`;

export const PUBLISHER_REGISTRATION = '/publisher/auth/registration';
export const PUBLISHER_LOGIN = '/publisher/auth/login';
export const PUBLISHER_FORGOT_PASSWORD = '/publisher/auth/forgot-password';
export const PUBLISHER_LISTINGS = '/publisher/listings';
export const PUBLISHER_CREATE_LISTING = '/publisher/listings/create';
export const PUBLISHER_VIEW_LISTING = '/publisher/listings/view';
export const MY_PUBLISHER_PROFILE = '/publisher/profile';

export const ADMIN = '/admin';
export const ADMIN_LOGIN = '/admin/auth/login';
export const ADMIN_FORGOT_PASSWORD = '/admin/auth/forgot-password';

export const SUBMISSION = '/submission';
export const USAGE = '/usage';
export const RESOURCES = '/resources';

export const PRODUCT_REVIEW_HISTORY = '/admin/product-review-history';
export const USER_MANAGEMENT = '/admin/member-management';
export const PUBLISHER_PENDING = '/publisher/pending';
export const PUBLISHER_REJECTED = '/publisher/rejected';
export const SITE_SETTINGS = '/admin/site-settings';
export const CATEGORIES = '/categories';
export const TAGS = '/tags';
export const SETTLEMENT_SETTINGS = '/settlement-settings';
export const SYSTEM_BALANCE = '/admin/system-balance';
export const TRANSACTION_MANAGEMENT_SALES =
  '/admin/transaction-management/sales';
export const TRANSACTION_MANAGEMENT_DEPOSITS =
  '/admin/transaction-management/deposits';
export const TRANSACTION_MANAGEMENT_WITHDRAWS =
  '/admin/transaction-management/withdraws';
export const TRANSACTION_MANAGEMENT_NOWPAYMENTS =
  '/admin/transaction-management/nowpayments';
export const PRODUCT_SUBMISSION_IN_REVIEW = '/admin';
export const PRODUCT_SUBMISSION_HISTORY = '/admin/product-review-history';
export const PRODUCT_MANAGEMENT = '/admin/product-management';
export const REFUND_PENDING = '/admin/refund/pending';
export const REFUND_PROCESSED = '/admin/refund/processed';
export const FEATURE_PRODUCTS = '/feature-products';

//FORUM
export const REPORTED_COMMENTS = '/forum/reported-comments';
export const FORUM_OVERVIEW = '/forum/overview';
export const FORUM_OVERVIEW_MOST_POPULAR = '/forum/overview/most-popular';
export const FORUM_OVERVIEW_MOST_DISCUSSED = '/forum/overview/most-discussed';
export const FORUM_MANAGEMENT = '/admin/forum-management';
export const FORUM_MY_THREADS = '/forum/my-threads';
export const FORUM_REPORTED_COMMENTS = '/forum/reported-comments';
export const FORUM_AI_TRENDS = '/forum/ai-trends';
export const FORUM_THREAD = '/forum/thread';

//MAINTENANCE
export const MAINTENANCE = '/maintenance';
