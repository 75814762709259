import { IContractErrorCodes, IWalletError } from '@interfaces';

import {
  CONTRACT_ERROR_CODES,
  DEFAULT_WALLET_ERROR_MESSAGE,
  WALLET_ACTION_ERROR_CODES,
} from '@shared/constants/errors';
import { toastError } from '@shared/utils';

export const walletErrorToast = (
  error: IWalletError,
  disableDefault = false
) => {
  const code = error && error.code;

  if (code === -32603) return;

  if (!WALLET_ACTION_ERROR_CODES[code]?.message && disableDefault) return;
  const errorMessage =
    WALLET_ACTION_ERROR_CODES[code]?.message ||
    (typeof CONTRACT_ERROR_CODES[code] === 'object'
      ? (CONTRACT_ERROR_CODES[code] as IContractErrorCodes)[error.reason]
      : CONTRACT_ERROR_CODES[code]) ||
    DEFAULT_WALLET_ERROR_MESSAGE;

  toastError(errorMessage as string);
};
