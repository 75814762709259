import { Fragment } from 'react';

export const outputMultilineText = (input: string) => {
  return input.split('\n').map((line, index) => (
    <Fragment key={index}>
      {line}
      <br />
    </Fragment>
  ));
};
