export function trimSearchInput(searchTerm: string) {
  if (!searchTerm) {
    return searchTerm;
  }

  searchTerm = searchTerm.trim();

  if (/\s{2,}/.test(searchTerm)) {
    searchTerm = searchTerm.replace(/\s+/g, ' ');
  }

  return searchTerm;
}
