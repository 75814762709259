export const disableScrollOnBody = (
  stopScrolling: boolean,
  isMobile: boolean = false
) => {
  // Check if document is defined, this is to avoid SSR errors
  if (typeof document === 'undefined') return;

  const classList = ['noScroll'];

  if (!isMobile) {
    classList.push('modal');
  }

  if (stopScrolling) {
    document.body.classList.add(...classList);
  } else {
    document.body.classList.remove(...classList);
  }
};
