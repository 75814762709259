import { jwtDecode } from 'jwt-decode';

interface IJwt {
  id: number;
  hash: string;
  iat: number;
  exp: number;
}

export const decodeJwt = (jwt: string) => {
  return jwtDecode(jwt) as IJwt;
};
