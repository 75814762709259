export const fetchApi = async (
  input: RequestInfo | URL,
  init?: RequestInit | undefined
) => {
  const res = await fetch(input, { ...init, cache: 'no-store' });

  if (res.status >= 400) {
    let error;

    try {
      error = await res.json();
    } catch {
      throw Error('An error occurred', {
        cause: res,
      });
    }

    throw new Error(
      typeof error.message === 'object'
        ? JSON.stringify(error.message)
        : error.message,
      { cause: res }
    );
  }

  return res;
};
