import { IContractErrorCodes, IMetamaskActionErrorCodes } from '@interfaces';

export const DEFAULT_ERROR_MESSAGE =
  'An error has occurred processing your request';

export const DEFAULT_NETWORK_ERROR_MESSAGE =
  'We are experiencing an issue loading your request - our engineering team has been notified.';

export const DEFAULT_WALLET_ERROR_MESSAGE = 'Request rejected';
// 'There was an issue processing your transaction - our engineering team has been notified.';
// TODO AM-725 updated because of this bug...check if another solution exists

export const WALLET_ACTION_ERROR_CODES: IMetamaskActionErrorCodes = {
  '-32002': {
    message: 'Metamask is locked, please open the extension before connecting',
  },
  4001: {
    message: 'Request rejected',
  },
  500: {
    message: 'Request rejected',
  },
  5000: {
    message: 'Request rejected',
  },
};

export const CONTRACT_ERROR_CODES: IContractErrorCodes = {
  ACTION_REJECTED: 'Request rejected',
  TRANSACTION_REPLACED: {
    cancelled: 'Your transaction has been cancelled',
    replaced: 'Your transaction has been replaced',
    repriced: 'Your transaction has been repriced',
  },
};

type LoginErrorCodes = {
  login: {
    [key: string]: string;
  };
  forgotPassword: {
    [key: string]: string;
  };
  otp: {
    [key: string]: string;
  };
  registration: {
    [key: string]: string;
  };
};

export const AUTH_ERROR_CODES: LoginErrorCodes = {
  login: {
    'Not found user': 'Sorry, no account was found',
  },
  forgotPassword: {
    'Not found user': 'The email is not linked to the account',
  },
  otp: {
    'Incorrect otp or expired': 'Invalid OTP key',
    'Not found user': 'Invalid OTP key',
  },
  registration: {
    'Already exists email': 'Email in use',
    'Already exists username': 'The username already exists, please try again',
  },
};
