import { toastError, toastSuccess } from '.';

export async function copyToClipboard(text: string): Promise<boolean> {
  try {
    await navigator.clipboard.writeText(text);
    toastSuccess('Text copied to clipboard.');
    return true;
  } catch (err) {
    toastError(
      'There was an issue copying to clipboard. Refresh the page and try again'
    );
    return false;
  }
}
