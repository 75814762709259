const DEFAULT_RAND_MINMAX = {
  min: 1,
  max: 100,
};

export const generateRandomNum = ({ min, max } = DEFAULT_RAND_MINMAX) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const generateRandomString = (length: number = 7) => {
  const randomChar = () => {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    return characters.charAt(Math.floor(Math.random() * charactersLength));
  };

  const result = Array.from({ length }, () => randomChar()).join('');

  return result;
};

export const coinToss = (): boolean => {
  const randomNum = Math.random();
  return randomNum < 0.5;
};
