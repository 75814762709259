import { formatLocaleString } from './strings';

const inifinitySign = '\u221E';

// TODO: I think this function can be removed for the formatBigNumber function
const formatNumbersWithK = (amount: number) => {
  if (amount == Number.MAX_SAFE_INTEGER) return inifinitySign;

  if (amount >= 1000) {
    const formattedSupply = (amount / 1000).toFixed(1);
    return formattedSupply.endsWith('.0')
      ? formattedSupply.slice(0, -2) + 'k'
      : formattedSupply + 'k';
  }
  return amount;
};

function removeScientificNotation(value: bigint | number) {
  return value.toLocaleString('fullwide', { useGrouping: false });
}

function isWithinRange(numberOne?: number, numberTwo?: number, range = 0.04) {
  if (!numberOne || !numberTwo) return false;

  return Math.abs(numberOne - numberTwo) <= range;
}

const formatDecimals = (number?: number | string, decimals = 4) => {
  if (!number) return;

  return +parseFloat((+number).toFixed(decimals)).toLocaleString('en-US', {
    useGrouping: false,
    maximumFractionDigits: decimals,
  });
};

const formatDisplayCurrency = (number?: number | string, decimals = 2) => {
  if (!number) return '0.00';

  const formattedWithDecimals = formatDecimals(number, decimals)!;

  return formatLocaleString(formattedWithDecimals);
};

const formatFlexibleDecimals = (
  number: number | string,
  minDecimals = 2,
  maxDecimals = 8
) => {
  if (!number) return '0.00';

  const numericValue = typeof number === 'string' ? parseFloat(number) : number;

  if (numericValue === 0) {
    return `0.${'0'.repeat(minDecimals)}`;
  }

  return numericValue.toLocaleString('en-US', {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
    useGrouping: true,
  });
};

export {
  formatNumbersWithK,
  removeScientificNotation,
  formatDecimals,
  formatDisplayCurrency,
  formatFlexibleDecimals,
  isWithinRange,
};
