import {
  ErrorToastIcon,
  InfoIcon,
  SuccessIcon,
  WarningIcon,
} from '@shared/assets';

export enum TOAST_VARIANT {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

export const TOAST_VARIANT_STYLE = {
  [TOAST_VARIANT.SUCCESS]: {
    icon: <SuccessIcon className="h-6 w-6 text-green500" />,
    className: 'bg-green900 border-green500 text-green100',
    titleText: 'text-green50',
  },
  [TOAST_VARIANT.ERROR]: {
    icon: <ErrorToastIcon className="h-6 w-6 text-red500" />,
    className: 'bg-red900 border-red500 text-red100',
    titleText: 'text-red50',
  },
  [TOAST_VARIANT.INFO]: {
    icon: <InfoIcon className="text-turquoise500 h-6 w-6" />,
    className: 'bg-turquoise900 border-neutral100 text-turquoise100',
    titleText: 'text-turquoise100',
  },
  [TOAST_VARIANT.WARNING]: {
    icon: <WarningIcon className="h-6 w-6 text-yellow500" />,
    className: 'bg-yellow900 border-yellow500 text-yellow100',
    titleText: 'text-yellow50',
  },
};
