import { AuthResponse } from '@interfaces';

import {
  getDomainRefreshToken,
  getRefreshTokenUrl,
  handleClientSideUnauthorizedRefreshTokenRequest,
  setDomainRefreshTokens,
} from '@api/requestBuilder/helpers';
import { refreshDomainToken } from '@api/requestBuilder/refreshToken/refreshTokenApi';

import '../helpers';

class RefreshTokenManager {
  private static instance: RefreshTokenManager;
  private refreshRequest?: Promise<AuthResponse | undefined>;

  static getInstance() {
    if (!this.instance) {
      this.instance = new RefreshTokenManager();
    }
    return this.instance;
  }

  async refreshTokens(calculatedUrl: string) {
    if (!this.refreshRequest) {
      this.refreshRequest = this.executeRefreshTokensRequest(calculatedUrl);
    }

    return this.refreshRequest;
  }

  private async executeRefreshTokensRequest(calculatedUrl: string) {
    const refreshToken = await getDomainRefreshToken(calculatedUrl);
    if (!refreshToken) return;

    const refreshTokenUrl = getRefreshTokenUrl(calculatedUrl);

    try {
      const response = await refreshDomainToken(refreshToken, refreshTokenUrl);
      await setDomainRefreshTokens(
        calculatedUrl,
        response.data.refreshToken,
        response.data.token
      );

      return response;
    } catch (e) {
      await handleClientSideUnauthorizedRefreshTokenRequest(calculatedUrl);
      throw e;
    } finally {
      this.refreshRequest = undefined;
    }
  }
}

export default RefreshTokenManager;
