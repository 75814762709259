export const formatNumberWithCommas = (
  number?: number,
  options?: Intl.NumberFormatOptions
) => {
  return number?.toLocaleString('en-US', {
    maximumFractionDigits: 8,
    ...options,
  });
};

export const formatUsdtValue = (valueStr: string) => {
  const value = parseFloat(valueStr);
  const formattedValue = value.toFixed(2);

  return formattedValue + ' USDT';
};

export const formatUsdaiValue = (
  valueStr: string,
  numberOfDecimals = 2,
  removeCurrency = false
) => {
  const value = parseFloat(valueStr);
  const formattedValue = value.toFixed(numberOfDecimals);

  return removeCurrency ? formattedValue : `${formattedValue} USDAI`;
};

export const formatAitechValue = (valueStr: string, numberOfDecimals = 2) => {
  const value = parseFloat(valueStr);
  const formattedValue = value.toFixed(numberOfDecimals);

  return formattedValue + ' AITECH';
};

export const formatPriceString = (
  valueStr: string | number | null,
  numberOfDecimals = 2
): number | undefined => {
  if (!valueStr) return undefined;
  const valueNum = parseFloat(valueStr.toString());

  return parseFloat(valueNum.toFixed(numberOfDecimals));
};

// valueStr=10 => output: 10, valueStr=10.8 => output: 10.8, valueStr=10.222222 => output: 10.22
export const formatPriceWithoutZeros = (
  valueStr: string | null,
  numberOfDecimals = 2,
  addCommas = false
): string | undefined => {
  if (!valueStr) return undefined;

  const value = parseFloat(valueStr);

  let formattedValue = value.toFixed(numberOfDecimals).replace(/\.?0+$/, '');

  if (addCommas) {
    const [integerPart, decimalPart] = formattedValue.split('.');

    const formattedIntegerPart = formatNumberWithCommas(+integerPart);

    formattedValue = decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart
        ? formattedIntegerPart
        : '0';
  }

  return formattedValue;
};
