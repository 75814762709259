import { formatFlexibleDecimals } from '@features/utils/numbers';

export const shortenString = (txHash: string) => {
  const firstPart = txHash?.slice(0, 9);
  const lastPart = txHash?.slice(txHash.length - 7, txHash.length);
  return `${firstPart}...${lastPart}`;
};

export const pluralize = (
  count?: number | string,
  singular?: string,
  specificPlural?: string
) => {
  if (specificPlural) {
    return count === 1 ? `${count} ${singular}` : `${count} ${specificPlural}`;
  }
  return count === 1 ? `${count} ${singular}` : `${count} ${singular}s`;
};

export const cutOffString = (str: string, length: number) => {
  return str.length > length ? `${str.substring(0, length)}...` : str;
};

export const formatNullableAmountWithCurrency = (
  amount: string | undefined | null,
  currency: string = ''
) => `${amount ? formatFlexibleDecimals(+amount) : '-'} ${currency}`;
