import { cache } from 'react';

import RefreshTokenManager from '@api/requestBuilder/refreshToken/refreshTokenManager';

import { UNAUTHORIZED_ERROR_CODE } from './constants';
import { fetchApi } from './fetchApi';
import { getDomainRefreshToken } from './helpers';
import { ICallableRequestBuilder } from './types';

export abstract class RequestBuilder<T> implements ICallableRequestBuilder<T> {
  constructor(
    arg: string | ((requestInit: RequestInit) => Promise<T>) | undefined
  ) {
    if (typeof arg === 'function') {
      this.callback = cache(arg);
    } else if (typeof arg === 'string') {
      this.url = arg;
    }
    this.requestInit = {};
  }

  public url?: string;
  public requestInit: RequestInit;
  public callback?: (requestInit: RequestInit) => Promise<T>;

  public async call(
    url?: string,
    updateRequestInit?: (init: RequestInit) => RequestInit
  ) {
    const updatedRequestInit = updateRequestInit
      ? updateRequestInit(this.requestInit)
      : this.requestInit;

    const calculatedUrl = url ? url : this.url;
    if (this.callback) {
      return await this.callback(updatedRequestInit);
    } else if (calculatedUrl) {
      return await this.getResponse(calculatedUrl, updatedRequestInit);
    } else {
      throw Error('Wrong ServerRequestBuilder call');
    }
  }

  private async getResponse(
    calculatedUrl: string,
    updatedRequestInit: RequestInit
  ) {
    const isServerSide = typeof window === 'undefined';

    try {
      const response = await fetchApi(calculatedUrl, updatedRequestInit);
      return await this.getDataFromFetchApiResponse(response);
    } catch (error) {
      if (error instanceof Error) {
        const errorCause = (error as Error).cause as { status: number };

        if (errorCause?.status === UNAUTHORIZED_ERROR_CODE && !isServerSide) {
          const refreshToken = await getDomainRefreshToken(calculatedUrl);

          if (refreshToken) {
            return await this.repeatRequestWithNewToken(
              calculatedUrl,
              updatedRequestInit
            );
          }
        }
      }
      throw error;
    }
  }

  private async repeatRequestWithNewToken(
    calculatedUrl: string,
    updatedRequestInit: RequestInit
  ) {
    const authResponse =
      await RefreshTokenManager.getInstance().refreshTokens(calculatedUrl);

    this.requestInit = {
      ...updatedRequestInit,
      headers: {
        ...updatedRequestInit.headers,
        Authorization: `Bearer ${authResponse?.data.token}`,
      },
    };

    const response = await fetchApi(calculatedUrl, this.requestInit);

    return await this.getDataFromFetchApiResponse(response);
  }

  private async getDataFromFetchApiResponse(response: Response) {
    const contentType = response.headers.get('Content-Type');

    try {
      if (contentType?.includes('application/json'))
        return (await response.json()) as T;

      return (await response.text()) as T;
    } catch (error) {
      throw new Error('Error parsing response', { cause: response });
    }
  }
}
